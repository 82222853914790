import React, { useState, useEffect } from 'react';
import './survey.css';

function SurveyApp() {
  const [frequency, setFrequency] = useState('');
  const [quality, setQuality] = useState('');
  const [feedback, setFeedback] = useState('');
  const [provereBlicLike, setProvereBlicLike] = useState(''); // State for the new question
  const [user, setUser] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userName = queryParams.get('user');
    if (userName) {
      setUser(userName);
    }
  }, []);

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const handleQualityChange = (event) => {
    setQuality(event.target.value);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleProvereBlicLikeChange = (event) => {
    setProvereBlicLike(event.target.value); // Handler for the new question
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      console.error('Error: No user specified for survey submission.');
      return; // Prevent the form from being submitted
    }

    const surveyData = {
      frequency,
      quality,
      feedback,
      provereBlicLike // Include the new question data
    };

    try {
      const response = await fetch(`https://survey.maksimmalbasa.in.rs/submit-survey/${user}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(surveyData)
      });

      const result = await response.json();
      console.log(result.message);

      setSuccessMessage('Thanks for filling out the form. Your submission was successfully saved. Thanks for participating.');
      setFrequency('');
      setQuality('');
      setFeedback('');
      setProvereBlicLike(''); // Reset the state for the new question
    } catch (error) {
      console.error('Failed to submit survey:', error);
      setSuccessMessage('');
    }
  };

  return (
    <div>
      <h1>Survey App</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="frequency">Da li volite frekvenciju mejlova?</label>
          <select id="frequency" value={frequency} onChange={handleFrequencyChange} required>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="quality">Da li volite kvalitet mejlova?</label>
          <select id="quality" value={quality} onChange={handleQualityChange} required>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="provereBlicLike">Da li vam se sviđa <a href="https://provere-blicevi.maksimmalbasa.in.rs/">Provere blicevi</a> sajt?</label>
          <select id="provereBlicLike" value={provereBlicLike} onChange={handleProvereBlicLikeChange} required>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="feedback">Hoćete još nešto da kažete?</label>
          <textarea id="feedback" value={feedback} onChange={handleFeedbackChange} />
        </div>
        <button type="submit">Submit</button>
        {successMessage && <div className="success-message">{successMessage}</div>}
      </form>
    </div>
  );
}

export default SurveyApp;
